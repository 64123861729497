import './Invest.sass'
import Img from '../../Images/ppa.jpg'

const Invest = () => {
    return ( 
        <main className="Invest">
            <div className="parent">
                <div className="head">
                    
                </div>
                <div className="body">
                    <div className="left">
                        <p className="bold">your flexibility in investment</p>
                        <p className="small">
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Suscipit facere, very Important.
                        </p>
                        <div className="list">
                            <div className="item">
                                <p className="number">01</p>
                                <p className="text">Invest From $5000</p>
                            </div>
                            <div className="item">
                                <p className="number">02</p>
                                <p className="text">Withdraw At Your Convenience</p>
                            </div>
                            <div className="item">
                                <p className="number">03</p>
                                <p className="text">Encrypted and Secured</p>
                            </div>
                        </div>
                        <div className="btn">Learn More</div>
                    </div>
                    <div className="right">
                        <div className="pic"><img src={Img} alt="" /></div>
                    </div>
                </div>
            </div>
        </main>
     );
}
 
export default Invest;