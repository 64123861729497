import Card from "../Components/Card/Card";
import Finance from "../Components/Finance/Finance";
import Footer from "../Components/Footer/Footer";
import Invest from "../Components/Invest/Invest";
import Main from "../Components/Main/Main";
import Plans from "../Components/Plans/Plans";
import Reviews from "../Components/Reviews/Reviews";
import Testimonial from "../Components/Testimonial/Testimonial";

const Home = () => {
    return ( 
        <div className="Home">
            <Main />
            <Card />
            <Invest />
            <Plans />
            <Reviews />
            <Testimonial />
            <Finance />
            <Footer />
        </div>
     );
}
 
export default Home;