import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material';
import {Fade} from 'react-reveal/Fade';
import './Footer.sass'

const Footer = () => {
    return ( 
        <main className="Footer">
            <div className="parent">
                <div className="a">
                    <p className='header' style={{fontWeight: 'bold', }}>BRIXTON</p>
                    <div className='wrapper'>
                        <p>Follow Us</p>
                        <div className="socials">
                            <Facebook />
                            <Instagram />
                            <Twitter />
                            <YouTube />
                        </div>
                    </div>
                    <div className="cont">
                        <input type="text" placeholder='Your Email' />
                        <p>Get Started</p>
                    </div>
                </div>
                <div className="allb">
                    <div className="b">
                        <p className="header">Contact</p>
                        <ul>
                            <li>Email Us</li>
                            <li>Company</li>
                            <li>Career</li>
                            <li>How it Work</li>
                        </ul>
                    </div>
                    <div className="b">
                    <p className="header">Support</p>
                        <ul>
                            <li>FAQs</li>
                            <li>Health Center</li>
                            <li>Consults</li>
                        </ul>
                    </div>
                    <div className="b">
                        <p className="header">Get in Touch</p>
                        <ul>
                            <li>+1234567890</li>
                            <li>info@brixton.com</li>
                        </ul>
                    </div>
                </div>
                </div>
        </main>
     );
}
 
export default Footer;