import React from "react";
import { Routes, Route } from "react-router-dom";
import Error from "./Components/Error/Error";
import Home from './Pages/Home'

const App = () => {
  return ( 
    <main className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/invest" element={<Home />} />
          <Route exact path="*" element={<Error />} />
        </Routes>
    </main>
   );
}
export default App;
