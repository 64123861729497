import './Testimonial.sass'
import { BsChat } from 'react-icons/bs'
import { Testimonials } from '../../Data/DataInfo';
const Testimonial = () => {

    return ( 
        <main className="Testimonial">
            <div className="parent">
                <div className="head">
                    <p className="services">Board</p>
                    <p className="header">Our Board Members</p>
                    <p className="lorem">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus quisquam accusantium reprehenderit sapiente?</p>
                </div>
                <div className="body">
                    <div className="cards">
                        {Testimonials.map((Testimonial, i)=>(
                            <div className="card">
                                <p className="text">{Testimonial.text}</p>
                                <div className="wrapper">
                                    <div className="cont">
                                        <div className="circle">
                                            <img src={Testimonial.img} alt="" />
                                        </div>
                                        <div className="cover">
                                            <p className="name">{Testimonial.name}</p>
                                            <p className="post">{Testimonial.post}</p>
                                        </div>
                                    </div>
                                    <BsChat />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </main>
     );
}
 
export default Testimonial;