import { NavLink } from 'react-router-dom';
import './PcNav.sass'

const PcNav = () => {
    return ( 
        <main className="PcNav">
            <nav>
                <div className='logo'>
                    <div className="icon"></div>
                    <span>BRIXTON</span>
                </div>
                <div className="links">
                    <ul>
                        <li><NavLink to='/'>Home</NavLink></li>
                        <li><NavLink to='/invest'>Investments</NavLink></li>
                        <li><NavLink to='/education'>Education</NavLink></li>
                        <li><NavLink to='/plans'>Plans</NavLink></li>
                        <li><NavLink to='/contact'>Contact</NavLink></li>
                        <li><NavLink to='/about'>About</NavLink></li>
                        <li><NavLink  to='/login'>Login / Signup</NavLink></li>
                    </ul>
                </div>
            </nav>
        </main>
     );
}
 
export default PcNav;