import Img1 from '../Images/ppa.jpg'
import Img2 from '../Images/ppc.jpg'
import Img3 from '../Images/ppb.jpg'
import { BsDashCircle, BsEyeFill, BsHeartFill, BsHeart, BsCreditCard, BsAlarm, BsRainbow, BsDiamond, BsVectorPen} from 'react-icons/bs'
export const Cardinfos= [
    {
        title: 'Investment Planning',
        img: <BsDashCircle className='icon'/>,
        subtitle: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora laborum in '
    },
    {
        title: 'Audit & Assurance',
        img: <BsEyeFill className='icon'/>,
        subtitle: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora laborum in '
    },
    {
        title: 'Business Taxation',
        img: <BsHeartFill className='icon'/>,
        subtitle: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora laborum in '
    },
    {
        title: 'Account Outsourcing',
        img: <BsCreditCard className='icon'/>,
        subtitle: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora laborum in '
    },
    {
        title: 'Payroll & Salary',
        img: <BsAlarm />,
        subtitle: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora laborum in '
    },
    {
        title: 'Loan & Management',
        img: <BsRainbow className='icon'/>,
        subtitle: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora laborum in '
    },
]

export const Plan = [
    {
        plan: 'Silver',
        percentage: '30%',
        minimum: '$1,000',
        maximum: '$5,000',
        commission: '5%',
        img: <BsHeart className='icon'/>
    },
    {
        plan: 'Gold',
        percentage: '35%',
        minimum: '$5,500',
        maximum: '$10,000',
        commission: '7%',
        img: <BsVectorPen className='icon'/>

    },
    {
        plan: 'Platinum',
        percentage: '45%',
        minimum: '$10,500',
        maximum: '$15,000',
        commission: '10%',
        img: <BsCreditCard className='icon'/>
    },
    {
        plan: 'Diamond',
        percentage: '60%',
        minimum: '$15,500',
        maximum: '$20,000',
        commission: '10%',
        img: <BsDiamond className='icon'/>
    }
]
















export const Testimonials = [
    {
        name: 'Clara Jepsen',
        post: 'CEO',
        img: Img1,
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora laborum in '
    },
    {
        name: 'Steven Chaw',
        post: 'Manager',
        img: Img2,
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora laborum in '
    },
    {
        name: 'Jonathan Doe',
        post: 'Consultant',
        img: Img3,
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora laborum in '
    },
]