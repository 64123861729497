import './Main.sass'
import Nav from '../Nav/Nav';
import Chain from '../../Images/chain.png'
import Fade from 'react-reveal/Fade';
const Main = () => {
    return ( 
        <main className="Main">
            <Fade duration='3000'>
                <div className="parent">
                    <Nav />
                    <div className="main">
                        <div className="left">
                            <p>BUILD ON WEB3. POWERED BY YOU.</p>
                            <p>The Future Of <span>Investment</span> Is Here</p>
                            <p>Invest on any tokens with a protocol trusted with billions for its performance and reliability.</p>
                            <div>
                                <p>GET STARTED</p>
                                <p>LEARN MORE</p>
                            </div>
                        </div>
                        <div className="right">
                            <img src={Chain} alt="" className='chain'/>
                            <div className="wrapper">
                                <p className="header">ACTIVE TRADERS</p>
                                <p className="text">10,250</p>
                            </div>
                            <div className="wrapper2">
                                <p className="header">MONTHLY DEPOSIT</p>
                                <p className="text">$22,500,000</p>
                            </div>
                            <div className="wrapper3">
                                <p className="header">MONTHLY WITHDRAWAL</p>
                                <p className="text">$30,500,000</p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="hidsden">
                        <div className="hidden-wrapper">
                            <p className="hidden-header">ACTIVE TRADERS</p>
                            <p className="hidden-text">10,250</p>
                        </div>
                        <div className="hidden-wrapper">
                            <p className="hidden-header">MONTHLY WITHDRAWAL</p>
                            <p className="hidden-text">$30,500,000</p>
                        </div>
                        <div className="hidden-wrapper">
                            <p className="hidden-header">MONTHLY DEPOSIT</p>
                            <p className="hidden-text">$25,500,000</p>
                        </div>
                    </div>
                </Fade>
        </main>
     );
}
 
export default Main;