import './Card.sass'
import { BsArrowRight } from 'react-icons/bs'
import { Cardinfos } from '../../Data/DataInfo';
import Fade from 'react-reveal/Fade';
const Card = () => {
    return ( 
        <main className="Card">
            <div className="parent">
                <div className="head">
                    <p className="services">Services</p>
                    <p className="header">What We Offer</p>
                    <p className="lorem">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus quisquam accusantium reprehenderit sapiente?</p>
                </div>
                <div className="body">
                    <div className="cards">
                        {Cardinfos.map((info, i)=>(
                            <div className="card">
                                {info.img}
                                <div className="wrapper">
                                    <p className='title'>{info.title}</p>
                                    <p className='sub'>{info.subtitle}</p>
                                    <div className='cont'>
                                        <span>Learn more</span> 
                                        <BsArrowRight className='right'/>
                                    </div>
                                </div>
                            </div>
                        ))}
                        
                    </div>
                </div>
                
            </div>
        </main>
     );
}
 
export default Card;