import { NavLink } from "react-router-dom";
import './Error.sass'
const Error = () => {
    return ( 
        <main className="Error">
            <div className="body">
            <h1>OOOPS!!! BAD REQUEST</h1>
            <p>Sorry... The Page Requested Does Not Exist </p>
            <p style={{display: 'flex'}}>
                <span>Click</span> 
                <NavLink to='/'>Here</NavLink>
                <span>to go to the Homepage</span>
            </p>
            </div>
        </main>
     );
}
 
export default Error;
