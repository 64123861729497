import { MoodRounded, KayakingRounded, SportsBasketballOutlined, CloudCircle } from '@mui/icons-material';
import './Finance.sass'
import Img from '../../Images/c.jpg'
import Fade from 'react-reveal/Fade';

const Finance = () => {
    return ( 
        <main className="Finance">
            <div className="parent">
                <div className="left">
                    <div id="left">
                        <img src={Img} alt="" />
                    </div>
                </div>
                <div className="right">
                    <div className="text">
                        <p>About Us</p>
                        <p>The Easiest Way to Manage Personal Finances</p>
                        <p className="sub">Hello Lorem ipsum dolor sit amet. ipsum dolor sit, amet consectetur adipisicing elit. Vero, modi. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae accusamus obcaecati sint nobis blanditiis quia corporis id nostrum expedita odio.</p>
                    </div>
                    <div className="wrapper">
                        <div className="icon">
                            <CloudCircle />
                        </div>
                        <div className="textwrapper">
                            <p className="title">Professional Advice</p>
                            <p className="sub">Hello Lorem ipsum dolor sit amet. ipsum dolor sit, amet consectetur adipisicing elit. Vero, modi.</p>
                        </div>
                    </div>
                    <div className="wrapper">
                        <div className="icon">
                            <SportsBasketballOutlined />
                        </div>
                        <div className="textwrapper">
                            <p className="title">Fully Independent </p>
                            <p className="sub">Hello Lorem ipsum dolor sit amet. ipsum dolor sit, amet consectetur adipisicing elit. Vero, modi.</p>
                        </div>
                    </div>
                    <div className="wrapper">
                        <div className="icon">
                            <KayakingRounded />
                        </div>
                        <div className="textwrapper">
                            <p className="title">Great Support </p>
                            <p className="sub">Hello Lorem ipsum dolor sit amet. ipsum dolor sit, amet consectetur adipisicing elit. Vero, modi.</p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
     );
}
 
export default Finance;