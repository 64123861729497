import './Reviews.sass'
import { BsStarFill } from 'react-icons/bs'

const Reviews = () => {
    return ( 
        <main className="Reviews">
            <div className="parent">
                <div className="head">
                    <p className="services">Reviews</p>
                    <p className="header">What Our Clients Say</p>
                    <p className="lorem">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus quisquam accusantium reprehenderit sapiente?</p>
                </div>
                <div className="body">
                    <div className="cards">
                        <div className="card">
                            <div className="circle"></div>
                            <div className="stars">
                                <BsStarFill />
                                <BsStarFill />
                                <BsStarFill />
                                <BsStarFill />
                                <BsStarFill />
                            </div>
                            <p className="text">"Lorem ipsum dolor sit amet consectetur adipisicing elit. VeritatisVeritatis assumenda."</p>
                            <p className="name">Eric Dyer</p>
                        </div>
                        <div className="card">
                            <div className="circle"></div>
                            <div className="stars">
                                <BsStarFill />
                                <BsStarFill />
                                <BsStarFill />
                                <BsStarFill />
                                <BsStarFill />
                            </div>
                            <p className="text">"Lorem ipsum dolor sit amet consectetur adipisicing elit. VeritatisVeritatis assumenda."</p>
                            <p className="name">Mitchell Lawrence</p>
                        </div>
                        <div className="card">
                            <div className="circle"></div>
                            <div className="stars">
                                <BsStarFill />
                                <BsStarFill />
                                <BsStarFill />
                                <BsStarFill />
                                <BsStarFill />
                            </div>
                            <p className="text">"Lorem ipsum dolor sit amet consectetur adipisicing elit. VeritatisVeritatis assumenda."</p>
                            <p className="name">Huawa McConor</p>
                        </div>
                    </div>
                    <div className="btn-section">
                        <p className="btn">See More Reviews</p>
                    </div>
                </div>
            </div>
        </main>
     );
}
 
export default Reviews;